import AccordionInfosCard from 'components/AccordionInfosCard'
import Button from 'components/Button'
import { navigate } from 'gatsby'
import { useLoader } from 'hooks/useLoader'
import { useLoans } from 'hooks/useLoans'
import AppLayout from 'layouts/AppLayout'
import { loansFormId } from 'utils/make/constants'
import makeTrackings from 'utils/make/trackings'
import storage from 'utils/Storage'
import { BASE_URL } from 'gatsby-env-variables'

import './LoanResume.scss'

const LoanResume = () => {
  const { selectedLoan, loanValue } = useLoans()
  const { setLoader } = useLoader()

  const userInfo = storage.session.getItem('user_data')

  const handleLoanConclusion = () => {
    setLoader({ isLoading: true, text: 'Solicitando empréstimo...' })

    setTimeout(() => {
      makeTrackings.formContinued({
        formId: loansFormId,
        formName: 'loans',
        stepId: 'df92db8f-9d7a-495c-a2ff-9935ea64637c',
        stepName: 'loans-resume',
        stepNumber: 4,
      })
      makeTrackings.formSubmitted({
        formId: loansFormId,
        formName: 'loans',
        stepId: '48b7490f-7c7a-4a17-97c8-2ed07f0f1416',
        stepName: 'loans-resume',
        stepNumber: 3,
      })

      makeTrackings.loan({
        selected: selectedLoan.id,
        category: selectedLoan.category,
        value: loanValue,
      })

      navigate('/emprestimos/conclusao')
      setTimeout(() => {
        setLoader({ isLoading: false })
      }, 1000)
    }, 2000)
  }

  return (
    <AppLayout
      contentHeaderInfos={{
        title: 'Sua proposta de crédito.',
        description:
          'Verifique as informações e solicite o contato de um de nossos especialistas.',
        onTrack: () => {
          makeTrackings.formBacked({
            formId: loansFormId,
            formName: 'loans',
            stepId: '95bb45bd-ee2b-4c85-9863-254589950f3a',
            stepName: 'loans-resume',
            stepNumber: 4,
          })
        },
      }}
      layoutClassName="loan-resume-layout"
      contentClassName="loan-resume"
    >
      <div className="loan-resume__content">
        <AccordionInfosCard
          title="Detalhes"
          infos={[
            { label: 'Tipo', value: selectedLoan?.type },
            { label: 'Valor', value: selectedLoan?.value },
            { label: 'Entrada', value: selectedLoan?.entry },
            { label: 'Parcela', value: selectedLoan?.installment },
            { label: 'Prazo', value: selectedLoan?.deadline },
            { label: 'Última parcela', value: selectedLoan?.lastInstallment },
            { label: 'Condições', value: selectedLoan?.conditions },
            { label: 'Taxa de juros', value: selectedLoan?.interestRate },
            { label: 'Juros e IOF', value: selectedLoan?.interestAndIof },
            { label: 'CET Anual', value: selectedLoan?.anualCET },
          ]}
          onTrack={() => {
            makeTrackings.elementClicked({
              actionOutcome: 'edit-details',
              elementType: 'icon',
              htmlId: '5d814880-452f-4baa-a70f-bb43984007a8',
              location: 'edit-section',
              name: 'click-form-loans-resume-details',
            })
          }}
          isEditable
          onEdit={() => {
            makeTrackings.elementClicked({
              actionOutcome: 'edit-personal-data',
              elementType: 'icon',
              htmlId: '944dbdf9-985f-4ca0-8637-ce18281a3ed5',
              location: 'edit-section',
              name: 'click-form-loans-resume-details-edit',
              text: 'editar',
              outboundUrl: `${BASE_URL}/emprestimos/selecao`,
            })

            navigate('/emprestimos/selecao')
          }}
        />

        <AccordionInfosCard
          title="Dados pessoais"
          infos={[
            { label: 'CPF', value: userInfo.document },
            { label: 'Nome', value: userInfo.name },
            { label: 'E-mail', value: userInfo.email },
            { label: 'Celular', value: userInfo.phone },
          ]}
          onTrack={() => {
            makeTrackings.elementClicked({
              actionOutcome: 'edit-personal-data',
              elementType: 'icon',
              htmlId: '5b91d462-7623-4607-8fbd-73b6a95f3d43',
              location: 'edit-section',
              name: 'click-form-loans-resume-personal-data',
            })
          }}
          isEditable
          onEdit={() => {
            makeTrackings.elementClicked({
              actionOutcome: 'edit-personal-data',
              elementType: 'icon',
              htmlId: '7aaf72fb-2e0c-467b-8e9e-e91f54b470bc',
              location: 'edit-section',
              name: 'click-form-loans-resume-personal-data-edit',
              text: 'editar',
              outboundUrl: `${BASE_URL}/registro`,
            })

            navigate('/registro', {
              state: {
                product: 'loans',
                isEditing: true,
                onBackFromEdit: '/emprestimos/resumo',
              },
            })
          }}
        />
      </div>

      <Button className="loan-resume__button" onClick={handleLoanConclusion}>
        Solicitar empréstimo
      </Button>
    </AppLayout>
  )
}

export default LoanResume
